<template>
  <b-col>
    <!-- form -->
    <validation-observer ref="bankDataForm">
      <b-form @submit.prevent="validationForm">
        <b-row>
          <b-col sm="12">
            <b-card
              v-for="( bankAccount, index ) in bankAccounts"
              :key="bankAccount+index"
              ref="row"
              :familiar="bankAccount+index"
              class="px-2"
            >
              <b-row>
                <!-- Bank Header -->
                <b-col
                  style="text-align: center"
                  lg="12"
                >
                  <h5 class="mt-1 mb-3">
                    DATOS DE CUENTA BANCARIA N° {{ index+1 }}
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Nombre del Banco"
                    :label-for="`${index}nameBank`"
                  >
                    <b-form-select
                      :id="`${index}nameBank`"
                      v-model="bankAccount.nameBank"
                      :options="optionsNameBank"
                      value-field="RAZON_SOCIAL"
                      text-field="RAZON_SOCIAL"
                      :name="`${index}nameBank`"
                    />
                  </b-form-group>
                </b-col>
                <!-- code bank -->
                <!-- <b-col md="6">
                  <b-form-group
                    label="Código"
                    :label-for="`${index}codeBank`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="codeBank"
                      rules="max:20"
                      :vid="`${index}codeBank`"
                    >
                      <b-form-input
                        :id="`${index}codeBank`"
                        v-model="bankAccount.codeBank"
                        :state="errors.length > 0 ? false:null"
                        :name="`${index}codeBank`"
                        readonly=''
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <!-- type Account-->
                <b-col md="6">
                  <b-form-group
                    label="Tipo de cuenta"
                    :label-for="`${index}typeAccount`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tipo de cuenta"
                      rules="max:30"
                      :vid="`${index}typeAccount`"
                    >
                      <b-form-select
                        :id="`${index}typeAccount`"
                        v-model="bankAccount.typeAccount"
                        :options="optionsTypeAccount"
                        :state="errors.length > 0 ? false:null"
                        :name="`${index}typeAccount`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- currency-->
                <b-col md="6">
                  <b-form-group
                    label="Moneda"
                    :label-for="`${index}currency`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Moneda"
                      rules="max:45"
                      :vid="`${index}currency`"
                    >
                      <b-form-select
                        :id="`${index}currency`"
                        v-model="bankAccount.currency"
                        :options="optionsTypeCurrency"
                        :state="errors.length > 0 ? false:null"
                        :name="`${index}currency`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Account Number-->
                <b-col md="6">
                  <b-form-group
                    label="Número de cuenta"
                    :label-for="`${index}accountNumber`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Número de cuenta"
                      rules="min:10|max:20"
                      :vid="`${index}accountNumber`"
                    >
                      <b-form-input
                        :id="`${index}accountNumber`"
                        v-model="bankAccount.accountNumber"
                        :state="errors.length > 0 ? false:null"
                        :name="`${index}accountNumber`"
                        :formatter="formatterNumber"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Interbank number Account-->
                <b-col md="6">
                  <b-form-group
                    label="Número de cuenta interbancaria"
                    :label-for="`${index}interbankAccountNumber`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Número de cuenta interbancaria"
                      rules="min:20|max:20"
                      :vid="`${index}interbankAccountNumber`"
                    >
                      <b-form-input
                        :id="`${index}interbankAccountNumber`"
                        v-model="bankAccount.interbankAccountNumber"
                        :state="errors.length > 0 ? false:null"
                        :name="`${index}interbankAccountNumber`"
                        :formatter="formatterNumber"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- isAccountHolder -->
                <b-col md="12">
                  <b-row class="justify-content-center my-2">
                    <b-form-group
                      label="¿El titular de la cuenta es usted?"
                      :label-for="`${index}isAccountHolder`"
                      style="text-align: center;"
                    >
                      <b-form-radio-group
                        :id="`${index}isAccountHolder`"
                        v-model="bankAccount.isAccountHolder"
                        class="text-center my-1"
                        :options="optionsAccountHolder"
                        value-field="value"
                        text-field="text"
                        :name="`${index}isAccountHolder`"
                      />
                    </b-form-group>
                  </b-row>
                </b-col>
                <b-col
                  v-if="bankAccount.isAccountHolder == 'no'"
                  ref="box1"
                  md="12"
                  class="repeater-form"
                >
                  <b-row>
                    <!-- names Other Account Holder -->
                    <b-col md="6">
                      <b-form-group
                        label="Nombres del titular"
                        :label-for="`${index}namesOtherAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Nombres del titular"
                          rules="max:100"
                          :vid="`${index}namesOtherAccountHolder`"
                        >
                          <b-form-input
                            :id="`${index}namesOtherAccountHolder`"
                            v-model="bankAccount.namesOtherAccountHolder"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}namesOtherAccountHolder`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- lastnames Other Account Holder -->
                    <b-col md="6">
                      <b-form-group
                        label="Apellidos del titular"
                        :label-for="`${index}lastnamesOtherAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Apellidos del titular"
                          rules="max:30"
                          :vid="`${index}lastnamesOtherAccountHolder`"
                        >
                          <b-form-input
                            :id="`${index}lastnamesOtherAccountHolder`"
                            v-model="bankAccount.lastnamesOtherAccountHolder"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}lastnamesOtherAccountHolder`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- type Document-->
                    <b-col md="6">
                      <b-form-group
                        label="Tipo de documento"
                        :label-for="`${index}typeDocumentOtherAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Tipo de documento"
                          :vid="`${index}typeDocumentOtherAccountHolder`"
                        >
                          <b-form-select
                            :id="`${index}typeDocument`"
                            v-model="bankAccount.typeDocumentOtherAccountHolder"
                            :options="optionsTypeDocument"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}typeDocumentOtherAccountHolder`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- document number -->
                    <b-col md="6">
                      <b-form-group
                        label="Número de documento"
                        :label-for="`${index}numberDocumentOtherAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Número de Documento"
                          :rules="`document:${bankAccount.typeDocumentOtherAccountHolder}`"
                          :vid="`${index}numberDocumentOtherAccountHolder`"
                        >
                          <b-form-input
                            :id="`${index}numberDocumentOtherAccountHolder`"
                            v-model="bankAccount.numberDocumentOtherAccountHolder"
                            :disabled="bankAccount.typeDocumentOtherAccountHolder ? false:true"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}numberDocumentOtherAccountHolder`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- activity -->
                    <b-col md="6">
                      <b-form-group
                        label="Ocupación*"
                        :label-for="`${index}activityOtherAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="ocupación"
                          :vid="`${index}activityOtherAccountHolder`"
                        >
                          <v-select
                            :id="`${index}activityOtherAccountHolder`"
                            v-model="bankAccount.activityOtherAccountHolder"
                            label="ocupacion"
                            :options="optionsActivities"
                            :name="`${index}activityOtherAccountHolder`"
                          >
                            <span slot="no-options">
                              Lo sentimos, no hay opciones que coincidan.
                            </span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- phone number-->
                    <b-col md="6">
                      <b-form-group
                        label="Número de teléfono"
                        :label-for="`${index}phoneNumberOtherAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Número de Teléfono"
                          rules="min:9|max:15"
                          :vid="`${index}phoneNumberOtherAccountHolder`"
                        >
                          <b-form-input
                            :id="`${index}phoneNumberOtherAccountHolder`"
                            v-model="bankAccount.phoneNumberOtherAccountHolder"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}phoneNumberOtherAccountHolder`"
                            :formatter="formatterTel"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- country -->
                    <b-col
                      md="6"
                    >
                      <b-form-group
                        label="Nacionalidad"
                        :label-for="`${index}nationalityOtherAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Nacionalidad"
                          :vid="`${index}nationalityOtherAccountHolder`"
                        >
                          <b-form-select
                            :id="`${index}nationalityOtherAccountHolder`"
                            v-model="bankAccount.nationalityOtherAccountHolder"
                            :options="optionsCountry"
                            text-field="varDescripcion"
                            value-field="varCodigo"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}nationalityOtherAccountHolder`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- address -->
                    <b-col
                      md="6"
                    >
                      <b-form-group
                        label="Dirección*"
                        :label-for="`${index}addressOtherAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Dirección"
                          rules="max:150"
                          :vid="`${index}addressOtherAccountHolder`"
                        >
                          <b-form-input
                            :id="`${index}addressOtherAccountHolder`"
                            v-model="bankAccount.addressOtherAccountHolder"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}addressOtherAccountHolder`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- UBIGEO -->
                    <b-col
                      md="6"
                    >
                      <!-- ubigee -->
                      <b-form-group
                        label="Ubigeo*"
                        :label-for="`${index}ubigeeOtherAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Ubigeo"
                          rules="min:6|max:6"
                          :vid="`${index}ubigeeOtherAccountHolder`"
                        >
                          <b-input-group>
                            <b-input-group-prepend>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-tooltip.hover.top="'Ubigeo son las siglas oficiales para Código de Ubicación Geográfica que usan el INEI, el RENIEC y la ONPE para codificar las circunscripciones'"
                                variant="outline-primary"
                                class="btn-icon"
                              >
                                <feather-icon
                                  size="15"
                                  icon="InfoIcon"
                                />
                              </b-button>
                            </b-input-group-prepend>
                            <b-form-input
                              :id="`${index}ubigeeOtherAccountHolder`"
                              v-model="bankAccount.ubigeeOtherAccountHolder"
                              :formatter="formatterNumber"
                              :state="errors.length > 0 ? false:null"
                              :name="`${index}ubigeeOtherAccountHolder`"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-row
                        class="justify-content-center m-2"
                      >
                        <b-badge
                          href="/ubigeo"
                          variant="light-primary"
                          target="_blank"
                          style="white-space: pre-wrap;"
                        > Puede encontrar su UBIGEO haciendo click AQUÍ </b-badge>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="bankAccount.isAccountHolder == 'yes'"
                  ref="box1"
                  md="12"
                  class="repeater-form"
                >
                  <b-row>
                    <!-- type Document-->
                    <b-col md="6">
                      <b-form-group
                        label="Tipo de documento de apertura"
                        :label-for="`${index}typeDocumentAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Tipo de documento"
                          :vid="`${index}typeDocumentAccountHolder`"
                        >
                          <b-form-select
                            :id="`${index}typeDocument`"
                            v-model="bankAccount.typeDocumentAccountHolder"
                            :options="optionsTypeDocument"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}typeDocumentAccountHolder`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- document number -->
                    <!-- <b-col md="6">
                      <b-form-group
                        label="Número de documento"
                        :label-for="`${index}numberDocumentAccountHolder`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Número de Documento"
                          :rules="`document:${bankAccount.typeDocumentAccountHolder}`"
                          :vid="`${index}numberDocumentAccountHolder`"
                        >
                          <b-form-input
                            :id="`${index}numberDocumentAccountHolder`"
                            v-model="bankAccount.numberDocumentAccountHolder"
                            :disabled="bankAccount.typeDocumentAccountHolder ? false:true"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}numberDocumentAccountHolder`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col> -->
                  </b-row>
                </b-col>
                <hr>
              </b-row>
              <b-row
                class="justify-content-end"
              >
                <!-- Buttons -->
                <b-col md="6">
                  <b-row>
                    <!-- Add Button -->
                    <b-col class="mb-50">
                      <b-row class="justify-content-center">
                        <b-button
                          variant="primary"
                          class="m-1 mt-md-2"
                          @click="repeateAgain"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span class="align-middle">Añadir nuevo</span>
                        </b-button>
                      </b-row>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      v-if="bankAccounts.length>0"
                      class="mb-50"
                    >
                      <b-row class="justify-content-center">
                        <b-button
                          variant="outline-danger"
                          class="m-1 mt-md-2"
                          @click="removeItem(index)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-25"
                          />
                          <span class="align-middle">Eliminar</span>
                        </b-button>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row
          v-if="bankAccounts.length==0"
        >
          <b-col
            sm="12"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="secondary"
              block
              type="button"

              @click="repeateAgain"
            >
              Agregar Banco
            </b-button>
          </b-col>
        </b-row>
        <b-row
          class="justify-content-center mt-4 mb-4"
        >
          <b-col
            sm="6"
            md="3"
          >
            <!-- return button -->
            <b-button
              class="my-1"
              variant="secondary"
              :to="{name:'Contact-person'}"
              block
            >
              Volver
            </b-button>
          </b-col>
          <b-col
            sm="6"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              Guardar y avanzar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormSelect, BFormRadioGroup, BFormGroup, BFormInput, BButton, BRow, BCol, BInputGroup, BInputGroupPrepend, BBadge, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import bankOptions from '@utils/bankOptions'
import typeAccountOptions from '@utils/typeAccountOptions'
import typeCurrencyOptions from '@utils/currencyOptions'
import countriesOptions from '@utils/countriesOptions'
import activitiesOptions from '@utils/activities'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCard,
    BForm,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      email: '',
      ruc: '',
      optionsTypeDocument: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: '1', text: 'DNI' },
        { value: '2', text: 'Carnét PNP' },
        { value: '3', text: 'Carnét FFAA' },
        { value: '4', text: 'Carnet Extranjeria' },
        { value: '6', text: 'RUC' },
        { value: '7', text: 'Pasaporte' },
      ],
      optionsTypeDeposit: [
        { value: '0', text: 'Cheque' },
        { value: '1', text: 'Depósito bancario' },
      ],
      optionsAccountHolder: [
        { value: 'yes', text: 'Soy el titular' },
        { value: 'no', text: 'El titular es otro' },
      ],
      optionsNameBank: bankOptions,
      optionsActivities: activitiesOptions,
      optionsTypeCurrency: typeCurrencyOptions,
      optionsTypeAccount: typeAccountOptions,
      optionsCountry: countriesOptions,
      bankAccounts: [],
      hasMainAccounts: false,
      mainBankAccounts: {},
      bankAccountsIsComplete: '0', // 0: No --- 1: Si
    }
  },
  watch: {
    bankAccounts: {
      handler() {
        for (let i = 0; i < this.bankAccounts.length; i += 1) {
          for (let j = 0; j < bankOptions.length; j += 1) {
            if (this.bankAccounts[i].nameBank === bankOptions[j].RAZON_SOCIAL) {
              this.bankAccounts[i].bank = bankOptions[j]
            }
          }
        }
      },

      deep: true,

    },
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    this.email = dataU.email
    this.ruc = dataU.ruc
    const banks = dataU.bankData ? dataU.bankData.bankAccounts : []
    const mba = dataU.mainBankAccountsData

    // Esto es para ver si se han registrado cuentas principales en la otra seccion
    if (mba !== undefined && mba.bankAccountUSD && mba.bankAccountPEN && mba.bankAccountUSD.typeDeposit !== '' && mba.bankAccountPEN.typeDeposit !== '') {
      this.hasMainAccounts = true
      this.mainBankAccounts = mba
    }

    if (banks !== undefined && banks.length > 0) {
      for (let i = 0; i < banks.length; i += 1) {
        this.bankAccounts.push({
          nameBank: banks[i].nameBank,
          typeAccount: banks[i].typeAccount,
          currency: banks[i].currency,
          accountNumber: banks[i].accountNumber,
          interbankAccountNumber: banks[i].interbankAccountNumber,
          isAccountHolder: banks[i].isAccountHolder,
          typeDocumentAccountHolder: banks[i].typeDocumentAccountHolder, // accountHolder -yes
          // numberDocumentAccountHolder: banks[i].numberDocumentAccountHolder,
          numberDocumentAccountHolder: '-',
          namesOtherAccountHolder: banks[i].namesOtherAccountHolder, // accountHolder - no
          lastnamesOtherAccountHolder: banks[i].lastnamesOtherAccountHolder,
          typeDocumentOtherAccountHolder: banks[i].typeDocumentOtherAccountHolder,
          numberDocumentOtherAccountHolder: banks[i].numberDocumentOtherAccountHolder,
          activityOtherAccountHolder: banks[i].activityOtherAccountHolder,
          phoneNumberOtherAccountHolder: banks[i].phoneNumberOtherAccountHolder,
          nationalityOtherAccountHolder: banks[i].nationalityOtherAccountHolder,
          addressOtherAccountHolder: banks[i].addressOtherAccountHolder,
          ubigeeOtherAccountHolder: banks[i].ubigeeOtherAccountHolder,
          completed: banks[i].completed,
        })
      }
    } else {
      this.repeateAgain()
    }
  },
  methods: {
    repeateAgain() {
      this.bankAccounts.push({
        nameBank: '',
        typeAccount: '',
        currency: '',
        accountNumber: '',
        interbankAccountNumber: '',
        isAccountHolder: 'yes',
        typeDocumentAccountHolder: '',
        numberDocumentAccountHolder: '',
        namesOtherAccountHolder: '',
        lastnamesOtherAccountHolder: '',
        typeDocumentOtherAccountHolder: '',
        numberDocumentOtherAccountHolder: '',
        activityOtherAccountHolder: {
          compuestoID: '000099',
          ocupacion: 'No declara',
        },
        phoneNumberOtherAccountHolder: '',
        nationalityOtherAccountHolder: '',
        addressOtherAccountHolder: '',
        ubigeeOtherAccountHolder: '',
        completed: false,
      })
    },
    removeItem(index) {
      this.bankAccounts.splice(index, 1)
    },
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterDoc(td) {
      return td === '0' ? this.formatterNumber : this.formatterChars
    },
    formatterTel(value) {
      return value.replace(/[^0-9()+]/g, '')
    },
    validationForm() {
      const bankData = []

      for (let i = 0; i < this.bankAccounts.length; i += 1) {
        const bankAccount = {}

        bankAccount.nameBank = this.bankAccounts[i].nameBank
        bankAccount.bank = this.bankAccounts[i].bank
        bankAccount.typeAccount = this.bankAccounts[i].typeAccount
        bankAccount.currency = this.bankAccounts[i].currency
        bankAccount.accountNumber = this.bankAccounts[i].accountNumber
        bankAccount.interbankAccountNumber = this.bankAccounts[i].interbankAccountNumber
        bankAccount.isAccountHolder = this.bankAccounts[i].isAccountHolder

        if (this.bankAccounts[i].isAccountHolder === 'yes') {
          bankAccount.typeDocumentAccountHolder = this.bankAccounts[i].typeDocumentAccountHolder
          bankAccount.numberDocumentAccountHolder = this.bankAccounts[i].numberDocumentAccountHolder
        }

        if (this.bankAccounts[i].isAccountHolder === 'no') {
          bankAccount.namesOtherAccountHolder = this.bankAccounts[i].namesOtherAccountHolder
          bankAccount.lastnamesOtherAccountHolder = this.bankAccounts[i].lastnamesOtherAccountHolder
          bankAccount.typeDocumentOtherAccountHolder = this.bankAccounts[i].typeDocumentOtherAccountHolder
          bankAccount.numberDocumentOtherAccountHolder = this.bankAccounts[i].numberDocumentOtherAccountHolder
          bankAccount.activityOtherAccountHolder = this.bankAccounts[i].activityOtherAccountHolder
          bankAccount.phoneNumberOtherAccountHolder = this.bankAccounts[i].phoneNumberOtherAccountHolder
          bankAccount.nationalityOtherAccountHolder = this.bankAccounts[i].nationalityOtherAccountHolder
          bankAccount.addressOtherAccountHolder = this.bankAccounts[i].addressOtherAccountHolder
          bankAccount.ubigeeOtherAccountHolder = this.bankAccounts[i].ubigeeOtherAccountHolder
        }

        bankData.push(bankAccount)
      }

      if (this.hasMainAccounts) {
        if (this.mainBankAccounts.bankAccountPEN.typeDeposit === '1') {
          this.mainBankAccounts.bankAccountPEN = {}
        }
        if (this.mainBankAccounts.bankAccountUSD.typeDeposit === '1') {
          this.mainBankAccounts.bankAccountUSD = {}
        }
      }

      this.$refs.bankDataForm.validate().then(success => {
        if (success) {
          useJwt.bankData({
            email: this.email,
            ruc: this.ruc,
            bankData,
            updateMainAccounts: this.hasMainAccounts,
            mbaPEN: this.mainBankAccounts.bankAccountPEN,
            mbaUSD: this.mainBankAccounts.bankAccountUSD,
          })
            .then(({ data }) => {
              const user = data.user.value
              localStorage.setItem('userData', JSON.stringify(user))
              store.dispatch(
                'elementVerticalMenu/setStatusStep',
                { step: 'BankAccounts-data', status: user.bankData.completed },
                { root: true },
              )
              if (this.hasMainAccounts) {
                store.dispatch(
                  'elementVerticalMenu/setStatusStep',
                  { step: 'MainBankAccounts-legal-data', status: user.mainBankAccountsData.completed },
                  { root: true },
                )
              }
              this.$router.replace('/registro/legal-cuentas-bancarias-titulares')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Se ha guardado tu información con éxito.',
                      autoHideDelay: 5000,
                    },
                  })
                })
            })
            .catch(error => {
              console.log('errores', error)
              this.$refs.bankDataForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
